import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { LargeFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/LargeFocusArticleTeaser.component";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import { RelatedArticles } from "@/components/RelatedArticles/RelatedArticles.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type LargeArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imagePosition?: "above" | "after" | "before";
  readonly imageSourceSetSize?: string;
  readonly trackingId?: string | null;
};
export function LargeArticleTeaser({
  className,
  article,
  imagePosition = "before",
  trackingId,
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 90vw, 560px`,
  hasImagePriority
}: LargeArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    content: {
      section,
      isFeature,
      headline,
      supportingImage,
      targetUrl,
      id,
      relatedArticles,
      lead
    },
    requiresSubscription
  } = article;
  const hasImage = supportingImage !== null;
  return isFeature ? <LargeFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} renderRelatedArticles={articles => <RelatedArticles relatedArticles={articles} type="bullet" isInverted />} trackingId={trackingId} data-sentry-element="LargeFocusArticleTeaser" data-sentry-component="LargeArticleTeaser" data-sentry-source-file="LargeArticleTeaser.component.tsx" /> : <article className={clsx(className, "h-full pb-3 text-black lg:pb-4")} data-sentry-component="LargeArticleTeaser" data-sentry-source-file="LargeArticleTeaser.component.tsx">
      <div className="grid auto-rows-auto grid-cols-1 gap-2 lg:grid-cols-12">
        {hasImage ? <div className={clsx(imagePosition === "after" && "lg:order-2", imagePosition !== "above" && "lg:col-span-7", "relative col-span-full")}>
            <div className="aspect-4/3">
              <ArticleTeaserImage alternativeText={supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={supportingImage.aspectRatio4By3} targetUrl={targetUrl} />
            </div>
          </div> : null}

        <div className={clsx(hasImage && imagePosition !== "above" && "lg:col-span-5", imagePosition === "after" && "lg:order-1", "col-span-full")}>
          {section !== null ? <SectionLink className="mb-1" targetUrl={section.targetUrl}>
              {section.name}
            </SectionLink> : null}

          <SnowplowLink className="block hover:underline" href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }} data-sentry-element="SnowplowLink" data-sentry-source-file="LargeArticleTeaser.component.tsx">
            <ArticleTeaserHeading className="text-4xl font-theme-bold lg:text-5xl" title={headline} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="LargeArticleTeaser.component.tsx" />
          </SnowplowLink>

          {lead !== null ? <SnowplowLink href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }}>
              <HtmlContent className="mt-2 text-base leading-snug transition-colors duration-150 hover:text-black lg:mt-2" html={lead.html} />
            </SnowplowLink> : null}

          {relatedArticles.length > 0 ? <RelatedArticles className="mt-2 text-charcoal" relatedArticles={relatedArticles} type="bullet" /> : null}

          {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}