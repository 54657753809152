import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["WatchNewsletterSignUp"] */ "/app/src/app/(sites)/watch/_components/newsletter/WatchNewsletterSignUp.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FrontPageRightNow"] */ "/app/src/app/(sites)/watch/[domain]/articles/_components/FrontpageRightNow.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReadAlsoArticles"] */ "/app/src/app/(sites)/watch/[domain]/articles/_components/ReadAlsoArticles.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdSlot"] */ "/app/src/components/AdSlot/AdSlot.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SetupPageAdSlots"] */ "/app/src/components/AdSlot/SetupPageAdSlots.component.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Byline"] */ "/app/src/components/ArticleContent/components/Byline/Byline.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FactBox"] */ "/app/src/components/ArticleContent/components/FactBox/FactBox.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RelatedArticleTeaser"] */ "/app/src/components/ArticleContent/components/RelatedArticleTeaser/RelatedArticleTeaser.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollTracker"] */ "/app/src/components/ArticleContent/components/ScrollTracker/ScrollTracker.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupportingImage"] */ "/app/src/components/ArticleContent/components/SupportingImage/SupportingImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HtmlContent"] */ "/app/src/components/HtmlContent/HtmlContent.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/src/components/Link/Link.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Paywall"] */ "/app/src/components/Paywall/Paywall.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LongPublicationDateTime"] */ "/app/src/components/PublicationDateTime/components/LongPublicationDateTime/LongPublicationDateTime.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShareButtonsContainer"] */ "/app/src/components/ShareButtons/ShareButtonsContainer.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/app/src/components/Translation/Translation.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/app/src/contexts/tracking/tracking.context.tsx");
