import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
type BadgeProps = {
  readonly className?: string;
  readonly children: string | ReactNode;
  readonly targetUrl?: string | null;
};
export function Badge({
  className,
  children,
  targetUrl = null
}: BadgeProps) {
  return <div className={clsx(className, "w-fit select-none bg-primary text-xs font-theme-bold uppercase text-white")} data-sentry-component="Badge" data-sentry-source-file="Badge.component.tsx">
      {targetUrl !== null ? <Link className="block px-1.5 py-1 transition-colors hover:bg-primary-hover" href={targetUrl}>
          {children}
        </Link> : <span className="block px-1.5 py-1">{children}</span>}
    </div>;
}