type BorderHeadingProps = {
  readonly children: string | JSX.Element;
};
export function BorderHeading({
  children
}: BorderHeadingProps) {
  return <>
      {/* To avoid Prettier from adding an infinite amount of backslashes, we need to add an ignore here. Issue has not been resolved yet: https://github.com/tailwindlabs/prettier-plugin-tailwindcss/issues/294 */}
      {/* prettier-ignore */}
      <h1 className="peer-[.\!hidden]:group-[]:pt-0 peer-[.\!hidden]:group-[]:border-t-0 select-none hyphens-manual break-words border-t-4 border-t-black pt-1 font-body font-theme-bold uppercase">
      {children}
    </h1>
    </>;
}